<template>
  <!--
    Show one configuration history item
  -->
  <div>
    <div class="row">
      <div class="col-4">
        <strong>{{ configuration.componentType }}{{ laneInformation }}</strong><br>configuration was changed {{ configuration.createDate | dateTime_fromNow }} by {{ configuration.user }}
      </div>
      <div class="col-4">
        <strong>Comment</strong>                
        <font-awesome-icon
          v-if="!configuration.comment"
          class="ml-2"
          icon="edit"
          @click="openCommentDialog"        
        />
        <br><i>{{ configuration.comment }}</i>  
      </div>
      <div class="col-4 text-right d-none d-md-block">
        <button
          v-if="!compare"
          class="btn btn-sm btn-primary"
          @click="compare = true"
        >
          Compare current and previous config
        </button>
        <button
          v-if="compare"
          class="btn btn-sm btn-danger"
          @click="restoreConfig"
        >
          Restore config
        </button>
      </div>
    </div>
    <div v-if="compare">
      <ConfigurationCompare
        :current-config="configuration.currentConfig"
        :previous-config="configuration.previousConfig"
      />
    </div>
    <Sidebar
      v-if="editComment"
      :show-sidebar="editComment"
      @close="hideSidebar"
    >
      <EditConfigurationHistoryComment
        :configuration="configuration"
        @closeSidebar="closeSidebar"
      />
    </Sidebar>
    <hr>
  </div>
</template>

<script>

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'ConfigurationHistoryItem',
  components: {   
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    ConfigurationCompare: () => import('@/components/Config/ConfigurationCompare.vue'),
    EditConfigurationHistoryComment: () => import('@/components/Config/EditConfigurationHistoryComment.vue')
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    configuration: {
      type: Object,
      required: true,
      default () {
        return {
          user: null,
          createDate: null,
          componentType: null,
          componentTypeId: null,
          currentConfig: null,
          currentConfigurationId: null,
          previousConfig: null,
          previousConfigurationId: null,
          comment: null
        }
      }
    }
  },
  data () {
    return {
      compare: false,
      editComment: false,
      clonedConfiguration: null
    };
  },
  computed: {
    laneInformation () {
      if (this.configuration.laneNumber && this.configuration.laneNumber == 1)
      {
        return ' - right lane';
      }
      else if (this.configuration.laneNumber && this.configuration.laneNumber == 2)
      {
        return ' - left lane';
      }
      return '';
    }
  },
  methods: {
    hideSidebar () {
        this.editComment = false;
        this.configuration.comment = this.clonedConfiguration.comment;
    },
    restoreConfig () {
      this.$emit('restoreConfig', this.configuration.componentTypeId, this.configuration.previousConfigurationId, this.configuration.laneNumber)
    },
    openCommentDialog () {
      if(this.configuration.comment) {
        return;
      }
      this.editComment = true;
      this.clonedConfiguration = Object.assign({}, this.configuration);
    },
    closeSidebar () {
      this.editComment = false;
    }
  }
}
</script>